// File: src/components/TextLoading.js

import { Box, Text, Flex } from "@chakra-ui/react";

const TextLoading = () => (
    <Flex direction="column" align="center" justify="center" height="100vh">
      <Box
        width="40px"
        height="40px"
        border="4px solid blue"
        borderTop="4px solid transparent"
        borderRadius="50%"
        animation="spin 1s linear infinite"
      />
      <Text mt={2}>LevelUp...</Text>
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </Flex>
  );

export default TextLoading;

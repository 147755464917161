// src/components/ScrollToTopButton.js

import React, { useState, useEffect } from "react";
import { IconButton, useColorModeValue } from "@chakra-ui/react";
import { FaArrowUp } from "react-icons/fa";

const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  // Call useColorModeValue at the top level of the component
  const hoverColor = useColorModeValue("gray.400", "gray.600");

  // Show or hide the button based on the scroll position
  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", toggleVisibility);

    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  // Scroll the window to the top smoothly
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {isVisible && (
        <IconButton
          aria-label="Scroll to top"
          icon={<FaArrowUp />}
          onClick={scrollToTop}
          position="fixed"
          bottom="40px"
          right="40px"
          zIndex="1000"
          colorScheme="primary"
          _hover={{
            bg: hoverColor,
          }}
        />
      )}
    </>
  );
};

export default ScrollToTopButton;

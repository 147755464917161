import "./App.css";
import { Route, Routes } from "react-router-dom";
import { Suspense, lazy } from "react";
import ItemsRouting from "./common/strings/routing";
import TextLoading from "./utils/helpers/loading";
import ScrollToTopButton from "./utils/helpers/scroll_to_top_button";
import ErrorBoundary from "./utils/helpers/boundary";
import { SpeedInsights } from '@vercel/speed-insights/react';
const Home = lazy(() => import("./features/pages/home"));
const Services = lazy(() => import("./features/pages/service"));
const About = lazy(() => import("./features/pages/about"));
const Contact = lazy(() => import("./features/pages/contact"));
const News = lazy(() => import("./features/pages/news"));
const SignIn = lazy(() => import("./features/authentication/signIn.auth"));
const SignUp = lazy(() => import("./features/authentication/signUp.auth"));
const ForgotPass = lazy(() => import("./features/authentication/forgot.auth"));
const ResetPass = lazy(() => import("./features/authentication/reset.auth"));
const EmailVerification = lazy(() =>
  import("./features/authentication/verification.auth")
);
function App() {
  const routes = ItemsRouting.getRouting();
  const authRoutes = ItemsRouting.getAuthRouting();
  return (
    <ErrorBoundary>
      <SpeedInsights />
      <Suspense
        fallback={
          <div>
            <TextLoading />
          </div>
        }
      >
        <Routes>
          <Route path={routes.Home} element={<Home />} />
          <Route path={routes.Services} element={<Services />} />
          <Route path={routes.About} element={<About />} />
          <Route path={routes.Contact} element={<Contact />} />
          <Route path={routes.News} element={<News />} />
          <Route path={authRoutes.SignIn} element={<SignIn />} />
          <Route path={authRoutes.SignUp} element={<SignUp />} />
          <Route path={authRoutes.Forgot} element={<ForgotPass />} />
          <Route path={authRoutes.Reset} element={<ResetPass />} />
          <Route
            path={authRoutes.Verification}
            element={<EmailVerification />}
          />
        </Routes>
        <ScrollToTopButton />
      </Suspense>
    </ErrorBoundary>
  );
}

export default App;

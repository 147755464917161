import { extendTheme } from '@chakra-ui/react';

const colors = {
  primary: {
    50: '#e4f0f5',
    100: '#bcd1df',
    200: '#93b2c9',
    300: '#6a93b3',
    400: '#41739d',
    500: '#003366', // Main brand color
    600: '#20486a',
    700: '#183650',
    800: '#102437',
    900: '#08121d',
  },
  secondary: {
    50: "#e3f7e9",
    100: "#b9ebc6",
    200: "#8edd9f",
    300: "#62cf79",
    400: " #3bc25c",
    500: "#50C878", ////(Base Color)
    600: " #46b56e",
    700: "#3b9e61",
    800: "#308854",
    900: "#226741",
  },
  red: {
    50: '#ffe5e5',
    100: '#fab3b3',
    200: '#f08080',
    300: '#e64d4d',
    400: '#db1a1a',
    500: '#c40000',
    600: '#9d0000',
    700: '#760000',
    800: '#4f0000',
    900: '#280000',
  },
};

const fonts = {
  heading: 'Poppins, Arial, sans-serif',
  body: 'Poppins, Roboto, sans-serif',
};

const components = {
  Button: {
    baseStyle: {
      fontWeight: 'bold',
      fontFamily: 'Poppins',
    },
  },
  Heading: {
    baseStyle: {
      fontWeight: 'bold',
      fontFamily: 'Poppins',
    },
  },
  ChakraLink: {
    baseStyle: {
      fontWeight: 'bold',
      fontFamily: 'Poppins',
    },
  },
  Input: {
    baseStyle: {
      fontWeight: 'bold',
      fontFamily: 'Poppins',
    },
  },
  Text: {
    baseStyle: {
      fontFamily: 'Poppins',
    },
  },
  Icon: {
    baseStyle: {},
  },
};

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const theme = extendTheme({
  config,
  colors,
  fonts,
  components,
  styles: {
    global: (props) => ({
      body: {
        bg: "#282c34",
        color: 'white',
      },
    }),
  },
});

export default theme;

